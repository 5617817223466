import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Toolbelt`}</h2>
    <p>{`Javscript(ES6+), Node.js, Express, React(Hooks), SQL, MongoDB, GraphQL(Apollo), Next.js, Gatsby, Heroku, Netlify, Git, Jira, MS Project, Azure DevOps, Open Source Contributor`}</p>
    <h6><em parentName="h6">{`This portfolio is based on LekoArts 'Cara' Gatsby theme and is automatically re-rendered on each push into my Github repo using Netlify.`}</em></h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      